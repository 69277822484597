<div class="ringCentral-block">

    <div id="dial-window">
        <div class="add-act">
            <a href="javascript:void(0)" title="" (click)="closeModal()" class="close" data-bs-dismiss="modal"
                aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
        </div>
        <div class="mask" *ngIf="isLoading">
            <img src="assets/images/loader.gif" alt="loader image" class="loderImg" />
        </div>
        <iframe width="100%" height="100%" (load)="onLoad()" [src]="rcLoginUrl" allow="autoplay; microphone">

        </iframe>
    </div>
</div>