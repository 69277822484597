import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialsService } from 'src/app/features/prospect/components/prospect-tab/services/dials.service';

@Component({
  selector: 'app-ring-central-login',
  templateUrl: './ring-central-login.component.html',
  styleUrls: ['./ring-central-login.component.css']
})
export class RingCentralLoginComponent implements OnInit {
  public rcLoginUrl!: SafeResourceUrl;
  @Input() listiner: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('iframeContainer') iframeContainer!: ElementRef;
  isLoading = true;
  constructor(
    private dialsService: DialsService,
    public activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.getRCloginUrl();
  }

  getRCloginUrl() {
    this.dialsService.rcUrl().subscribe((res: any) => {
      if (res) {
        this.loadSite(res.ringCentralLoginUrl)
      }
    })
  }

  closeModal() {
    this.activeModal.close();
  }

  loadSite(rcUrl: any) {
    this.isLoading = true;
    this.rcLoginUrl = this.sanitizer.bypassSecurityTrustResourceUrl(rcUrl);
  }

  onLoad() {
    this.isLoading = false;
  }
}
